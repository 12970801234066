<template>
    <form class="l-stack l-gap-3" @submit.prevent="handleSubmit">
        <div class="l-stack l-gap-2">
            <base-multiselect
                v-model="condition"
                :options="conditionOptions"
                :custom-label="option => $t(`shared.measurements.${option}`)"
                :placeholder="$t('conditionPlaceholder')"
                @input="clearValue"
            />

            <div v-if="condition === 'battery_status'">
                <base-multiselect
                    v-model="value"
                    :options="batteryStatusOptions"
                    :custom-label="
                        option =>
                            $t(
                                'shared.measurementOptions.battery_status.' +
                                    option
                            )
                    "
                    :placeholder="$t('value')"
                />
            </div>

            <div
                v-else-if="condition === 'geofence'"
                class="l-inline l-gap-1 l-center-v conditions-row"
            >
                <base-multiselect
                    v-model="operator"
                    :options="geofenceOptions"
                    :custom-label="option => $t(option)"
                    :searchable="false"
                    :placeholder="$t('position')"
                />

                <base-multiselect
                    v-model="value"
                    :options="getLocationsSortedByName"
                    track-by="id"
                    label="name"
                    :placeholder="$t('location')"
                />
            </div>

            <template v-else-if="condition === 'regex'">
                <base-input
                    v-model="$v.name.$model"
                    :block="false"
                    :placeholder="$t('customConditionName')"
                />

                <base-input
                    v-model="value"
                    :block="false"
                    :placeholder="$t('value')"
                />
            </template>

            <div
                v-else-if="condition === 'state'"
                class="l-inline l-gap-1 l-center-v conditions-row"
            >
                <base-multiselect
                    v-model="operator"
                    :options="stateChannelOptions"
                    :custom-label="option => $t(option)"
                    :searchable="false"
                    :placeholder="$t('channel')"
                />

                <base-multiselect
                    v-model="value"
                    :options="stateOptions"
                    :custom-label="option => $t(option)"
                    :searchable="false"
                    :placeholder="$t('value')"
                />
            </div>

            <template v-else-if="condition === 'temperature'">
                <base-multiselect
                    v-model="name"
                    :options="temperatureChannelOptions"
                    :custom-label="option => $t(option)"
                    :preselect-first="true"
                    :allow-empty="false"
                    :searchable="false"
                    :placeholder="$t('channel')"
                />

                <div class="l-inline l-gap-1 l-center-v conditions-row">
                    <base-multiselect
                        v-model="operator"
                        :options="operatorOptions"
                        :custom-label="option => operatorTransalations[option]"
                        :searchable="false"
                        :placeholder="$t('operator')"
                    />

                    <base-input
                        v-model.number="$v.value.$model"
                        type="number"
                        step="any"
                        :block="false"
                        :placeholder="$t('value')"
                    >
                        <template #postfix>
                            {{ units.temperature }}
                        </template>
                    </base-input>
                </div>
            </template>

            <div
                v-else-if="condition === 'offline'"
                class="l-inline l-gap-1 l-center-v conditions-row"
            >
                <base-input
                    v-model.number="timeValue"
                    class="l-full-width"
                    type="number"
                    :block="false"
                    :placeholder="$t('value')"
                >
                    <template #postfix>
                        {{ $t('time-h') }}
                    </template>
                </base-input>
            </div>

            <template v-else-if="condition">
                <base-input
                    v-if="condition === 'custom'"
                    v-model="$v.name.$model"
                    :block="false"
                    :placeholder="$t('customConditionName')"
                />

                <base-multiselect
                    v-if="condition === 'voltage'"
                    v-model="$v.name.$model"
                    :options="fenceGuardVoltageOptions"
                    :custom-label="
                        option => $t(`shared.measurements.${option}`)
                    "
                    :searchable="false"
                    :placeholder="$t('fenceGuardVoltagePlaceholder')"
                />

                <div class="l-inline l-gap-1 l-center-v conditions-row">
                    <base-multiselect
                        v-model="operator"
                        :options="operatorOptions"
                        :custom-label="option => operatorTransalations[option]"
                        :searchable="false"
                        :placeholder="$t('operator')"
                    />

                    <base-input
                        v-model.number="$v.value.$model"
                        type="number"
                        step="any"
                        :block="false"
                        :placeholder="$t('value')"
                    >
                        <template #postfix>
                            {{ units[condition] }}
                        </template>
                    </base-input>
                </div>
            </template>
        </div>

        <p
            v-show="$v.name && $v.name.$dirty && $v.name.$invalid"
            class="form-error"
        >
            {{ $t('invalidName') }}
        </p>

        <p
            v-show="$v.value && $v.value.$dirty && $v.value.$invalid"
            class="form-error"
        >
            {{ $t('invalidValue') }}
        </p>

        <div class="l-inline l-spread l-center-v">
            <span class="clickable" @click="$emit('close')">
                {{ $t('shared.cancel') }}
            </span>

            <base-button size="small" type="submit" :disabled="isDisabled">
                {{ $t('shared.save') }}
            </base-button>
        </div>
    </form>
</template>

<script>
import { mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'

import { measurementHelper } from '@/utils'

import BaseButton from './BaseButton'
import BaseInput from './BaseInput'
import BaseMultiselect from './BaseMultiselect'

export default {
    name: 'NotificationConditionForm',
    components: {
        BaseButton,
        BaseInput,
        BaseMultiselect,
    },
    props: {
        initialValue: {
            type: Object,
            default: undefined,
        },
    },
    data() {
        return {
            batteryStatusOptions: ['ok', 'warning', 'critical'],
            condition: null,
            conditionOptions: [
                'battery',
                'battery_status',
                'battery_voltage',
                'co2',
                'distance',
                'fill_level',
                'geofence',
                'humidity',
                'level',
                'mass',
                'moisture',
                'offline',
                'power',
                'running_time',
                'speed',
                'state',
                'temperature',
                'voc',
                'voltage',
                'volume',
            ],
            fenceGuardVoltageOptions: [
                'voltage_0',
                'voltage_1',
                'voltage_2',
                'voltage_3',
                'voltage_4',
            ],
            geofenceOptions: ['inside', 'outside'],
            stateOptions: ['on', 'off'],
            stateChannelOptions: ['d1', 'd2', 'd3', 'd4', 'd5', 'd6'],
            temperatureChannelOptions: [
                't0',
                't1',
                't2',
                't3',
                't4',
                't5',
                't6',
            ],
            noOperatorConditionOptions: ['battery_status', 'offline', 'regex'],
            operator: null,
            operatorOptions: ['<', '=', '>'],
            operatorTransalations: {
                '<': this.$t('lt'),
                '=': this.$t('eq'),
                '>': this.$t('gt'),
            },
            timeOptions: {
                h: 60 * 60,
                min: 60,
            },
            timeUnit: 'h',
            timeValue: 24,
            name: null,
            value: null,
            units: measurementHelper.units,
        }
    },
    validations() {
        return {
            ...(/^(custom|regex|voltage)$/.test(this.condition) && {
                name: {
                    required,
                },
            }),
            ...(this.condition !== 'offline' && {
                value: {
                    required,
                },
            }),
        }
    },
    computed: {
        ...mapGetters('authentication', ['isStaff']),
        ...mapGetters('locations', ['getLocationsSortedByName']),
        isDisabled() {
            return (
                this.$v.name?.$error ||
                this.$v.value?.$error ||
                !this.condition ||
                (!this.operator &&
                    !this.noOperatorConditionOptions.includes(
                        this.condition
                    )) ||
                (!this.value && !this.timeValue)
            )
        },
    },
    watch: {
        initialValue() {
            this.setInitialValue()
        },
    },
    mounted() {
        if (this.isStaff) {
            this.conditionOptions = [
                ...this.conditionOptions,
                'custom',
                'lower_loop',
                'potential',
                'probe_state',
                'regex',
                'tau',
                'upper_loop',
                'water_level',
            ].sort()
        }

        this.setInitialValue()
    },
    methods: {
        clearValue() {
            this.operator = null
            this.value = null
            this.$v.$reset()
        },
        handleSubmit() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                return
            }

            let payload

            switch (this.condition) {
                case 'battery_status':
                    payload = {
                        type: 'regex',
                        value: this.condition,
                        regex: this.value,
                    }
                    break
                case 'geofence':
                    payload = {
                        type: 'geofence',
                        position: this.operator,
                        locationId: this.value.id,
                    }
                    break
                case 'offline':
                    payload = {
                        type: 'offline',
                        seconds: Math.round(
                            this.timeValue * this.timeOptions[this.timeUnit]
                        ),
                        user_unit: this.timeUnit,
                    }
                    break
                case 'regex':
                    payload = {
                        type: 'regex',
                        value: this.name,
                        regex: this.value,
                    }
                    break
                case 'state':
                    payload = {
                        type: 'state',
                        state: this.value === 'on',
                        value: this.operator,
                        name: 'On',
                    }
                    break
                default:
                    payload = {
                        type: 'threshold',
                        operator: this.operator,
                        threshold:
                            this.condition === 'fill_level'
                                ? this.value / 100
                                : this.value,
                        value: /^(custom|temperature|voltage)$/.test(
                            this.condition
                        )
                            ? this.name === 't0'
                                ? this.condition
                                : this.name
                            : this.condition,
                    }
                    break
            }

            this.$emit('close', payload)
        },
        setInitialValue() {
            switch (this.initialValue?.type) {
                case 'geofence':
                    this.condition = this.initialValue.type
                    this.operator = this.initialValue.position
                    this.value = this.getLocationsSortedByName.find(
                        item => item.id === this.initialValue.locationId
                    )
                    break
                case 'offline':
                    this.condition = this.initialValue.type
                    this.timeUnit = this.initialValue.user_unit
                    this.timeValue =
                        this.initialValue.seconds /
                        this.timeOptions[this.timeUnit]
                    break
                case 'regex':
                    if (this.initialValue.value === 'battery_status') {
                        this.condition = this.initialValue.value
                    } else {
                        this.condition = 'regex'
                        this.name = this.initialValue.value
                    }
                    this.value = this.initialValue.regex
                    break
                case 'state':
                    this.condition = this.initialValue.type
                    this.operator = this.initialValue.value
                    this.value = this.initialValue.state ? 'on' : 'off'
                    break
                case 'threshold':
                    if (/^(t\d|temperature)$/.test(this.initialValue.value)) {
                        this.condition = 'temperature'
                        this.name =
                            this.initialValue.value === this.condition
                                ? 't0'
                                : this.initialValue.value
                    } else if (/^voltage_\d+$/.test(this.initialValue.value)) {
                        this.condition = 'voltage'
                        this.name = this.initialValue.value
                    } else if (
                        this.conditionOptions.includes(this.initialValue.value)
                    ) {
                        this.condition = this.initialValue.value
                    } else {
                        this.condition = 'custom'
                        this.name = this.initialValue.value
                    }
                    this.operator = this.initialValue.operator
                    this.value =
                        this.condition === 'fill_level'
                            ? this.initialValue.threshold * 100
                            : this.initialValue.threshold
                    break
            }
        },
    },
}
</script>

<i18n>
{
    "en": {
        "channel": "Channel",
        "conditionPlaceholder": "Choose Measurement",
        "customConditionName": "Name",
        "d1": "Channel 1",
        "d2": "Channel 2",
        "d3": "Channel 3",
        "d4": "Channel 4",
        "d5": "Channel 5",
        "d6": "Channel 6",
        "eq": "Equals",
        "fenceGuardVoltagePlaceholder": "Voltage",
        "gt": "Greater than",
        "inside": "Inside",
        "invalidName": "Invalid name",
        "invalidValue": "Invalid value",
        "location": "Location",
        "lt": "Less than",
        "off": "Off",
        "on": "On",
        "operator": "Operator",
        "outside": "Outside",
        "position": "Position",
        "running_time": "Operating hours",
        "t0": "Channel 0",
        "t1": "Channel 1",
        "t2": "Channel 2",
        "t3": "Channel 3",
        "t4": "Channel 4",
        "t5": "Channel 5",
        "t6": "Channel 6",
        "time-h": "Hours",
        "time-min": "Minutes",
        "unit": "Unit",
        "unit": "Unit",
        "value": "Value"
    },
    "de": {
        "channel": "Kanal",
        "conditionPlaceholder": "Messwert auswählen",
        "customConditionName": "Name",
        "d1": "Kanal 1",
        "d2": "Kanal 2",
        "d3": "Kanal 3",
        "d4": "Kanal 4",
        "d5": "Kanal 5",
        "d6": "Kanal 6",
        "eq": "Gleich",
        "fenceGuardVoltagePlaceholder": "Spannung",
        "gt": "Grösser als",
        "inside": "Innerhalb",
        "invalidName": "Ungültiger Name",
        "invalidValue": "Ungültiger Wert",
        "location": "Standort",
        "lt": "Kleiner als",
        "off": "Aus",
        "on": "Ein",
        "operator": "Bedingung",
        "outside": "Ausserhalb",
        "position": "Position",
        "running_time": "Betriebsstunden",
        "t0": "Kanal 0",
        "t1": "Kanal 1",
        "t2": "Kanal 2",
        "t3": "Kanal 3",
        "t4": "Kanal 4",
        "t5": "Kanal 5",
        "t6": "Kanal 6",
        "time-h": "Stunden",
        "time-min": "Minuten",
        "unit": "Einheit",
        "unit": "Einheit",
        "value": "Wert"
    },
    "it": {
        "channel": "Channel",
        "conditionPlaceholder": "Scegliere la misura",
        "customConditionName": "Nome",
        "d1": "Canale 1",
        "d2": "Canale 2",
        "d3": "Canale 3",
        "d4": "Canale 4",
        "d5": "Canale 5",
        "d6": "Canale 6",
        "eq": "equivale a",
        "fenceGuardVoltagePlaceholder": "Voltaggio",
        "gt": "maggiore di",
        "inside": "All'interno di",
        "invalidName": "Nome non valido",
        "invalidValue": "Valore non valido",
        "location": "Posizione",
        "lt": "Meno di",
        "off": "Off",
        "on": "On",
        "operator": "Operatore",
        "outside": "All'esterno di",
        "position": "Sosizione",
        "running_time": "Tempo di funzionamento",
        "t0": "Canale 0",
        "t1": "Canale 1",
        "t2": "Canale 2",
        "t3": "Canale 3",
        "t4": "Canale 4",
        "t5": "Canale 5",
        "t6": "Canale 6",
        "time-h": "Ore",
        "time-min": "Minuti",
        "unit": "Unità",
        "unit": "Unità",
        "value": "Valore"
    }
}
</i18n>

<style lang="scss" scoped>
.conditions-row {
    &:first-child {
        min-width: 60%;
    }
}

.form-error {
    margin-bottom: 0;
}
</style>
